import { Avatar, Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Zoom from "@material-ui/core/Zoom";
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Card, ClickAwayListener } from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleRemoteOpen } from "../../actions/DoorApiCalls";
import { REACT_URL_DOOR } from "../../actions/EndPoints";
import door_Img from "../../assets/images/dashboard-icons/door.svg";
import CustomPopover from "../../components/CustomPopover";
import CommonUtil from "../../components/Util/CommonUtils";
import CustomColorPicker from "./ColorPicker";
import DoorIcon from "./DoorIcon";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 8,
    border: "1px solid #36C96D",
    maxWidth: 350,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#36C96D !important",
    color: "#fff",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #36C96D",
    padding: "10px",
  },
}))(Tooltip);

export default function PathWayItem(props) {
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [colorPicker, openColorPicker] = React.useState(false);
  const [color, setColor] = React.useState(props.data.doorColor);
  const [doorState, setDoorState] = useState("Remote normally open");
  const [isActive, setActive] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [doorAlert, setDoorAlert] = React.useState({});

  const handleClose = () => {
    openColorPicker(false);
    setAnchorEl(false);
  };

  const openDoorManagement = () => {
    history.push({
      pathname: REACT_URL_DOOR,
    });
  };

  const handleDoorOpenClose = (doorId) => {
    var operation =
      doorState === "Remote normally open" ? "normalOpenDoor" : "closeDoor";
    dispatch(handleRemoteOpen(null, operation, [doorId])).then((res) => {});
    setDoorState(
      doorState === "Remote normally open"
        ? "Normally close"
        : "Remote normally open"
    );
    handleClose();
  };

  const openColorChange = () => {
    openColorPicker(!colorPicker);
  };

  useEffect(() => {
    if (
      props.data.doorState === "Normally Open" ||
      props.data.doorState === "None"
    ) {
      setDoorState(t("COMMON249"));
    } else {
      setDoorState("Normally close");
    }
  }, []);

  useEffect(() => {
    // if (store.dashBoard.activeDoors.includes(props.data.doorId)) {
    //   setInterval(() => {}, 5000);
    // }
    var activeDoors = store.dashBoard.activeDoors;
    if (activeDoors instanceof Array) {
      activeDoors.map((doorEvent, i) => {
        if (doorEvent.eventPointId === props.data.doorId) {
          setDoorAlert(doorEvent);
          setActive(true);
          setTimeout(() => {
            setActive(false);
            setDoorAlert({});
          }, 5000);
        }
      });
    }
  }, [store.dashBoard.activeDoors]);

  return (
    <Grid container item xs={2} sm={2} md={1} alignItems="center">
      {!isActive && (
        <CustomPopover
          color={color}
          arrow
          TransitionComponent={Zoom}
          title={
            <ClickAwayListener onClickAway={() => handleClose()}>
              <div aria-haspopup="true" onMouseLeave={() => handleClose()}>
                {!colorPicker && (
                  <Card
                    style={{
                      backgroundColor: "#36C96D !important",
                      border: "1px solid " + color,
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent={"center"}
                        width="100%"
                        p={1}
                      >
                        <Box flexGrow={1}></Box>
                        <Box>
                          <img
                            src={door_Img}
                            alt="location"
                            height={30}
                            width={30}
                          />
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            aria-controls="simple-menu"
                            aria-haspopup="false"
                            onClick={handleClick}
                            style={{ marginLeft: "30px" }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      </Box>

                      <Menu
                        style={{ zIndex: "99999" }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => openDoorManagement()}
                          style={{ fontSize: "12px" }}
                        >
                          {t("DOORI014")}
                        </MenuItem>
                        {props.data.doorStatus === "Active" &&
                          CommonUtil.isEmptyString(props.data.doorType) && (
                            <MenuItem
                              onClick={() => {
                                openColorChange();
                              }}
                              style={{ fontSize: "12px" }}
                            >
                              {t("DOORI035")}
                            </MenuItem>
                          )}
                        {props.data.doorStatus === "Active" && (
                          <MenuItem
                            onClick={() =>
                              handleDoorOpenClose(props.data.doorId)
                            }
                            style={{ fontSize: "12px" }}
                          >
                            {doorState}
                          </MenuItem>
                        )}
                      </Menu>
                    </div>

                    <div>
                      <Box
                        display="flex"
                        flexDirection="row"
                        bgcolor="background.paper"
                      >
                        <Box minWidth={"50%"}>
                          <Typography gutterBottom style={{ fontSize: "12px" }}>
                            {t("DOORI003")}
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title={props.data.doorName}>
                            <Typography
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              :{" "}
                              <span
                                style={{
                                  color: "gray",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {props.data.doorName}
                              </span>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        bgcolor="background.paper"
                      >
                        <Box minWidth={"50%"}>
                          <Typography gutterBottom style={{ fontSize: "12px" }}>
                            {t("COMMON082")}
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title={props.data.siteName}>
                            <Typography
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              :{" "}
                              <span
                                style={{
                                  color: "gray",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {props.data.siteName}
                              </span>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        bgcolor="background.paper"
                      >
                        <Box minWidth={"50%"}>
                          <Typography gutterBottom style={{ fontSize: "12px" }}>
                            {t("TIMEP001")}
                          </Typography>
                        </Box>
                        <Box flexShrink={0}>
                          <Tooltip
                            title={
                              props.data.activeTimePeriod
                                ? props.data.activeTimePeriod
                                : "-"
                            }
                          >
                            <Typography
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              :{" "}
                              <span style={{ color: "gray" }}>
                                {props.data.activeTimePeriod
                                  ? props.data.activeTimePeriod
                                  : "-"}
                              </span>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        bgcolor="background.paper"
                      >
                        <Box minWidth={"50%"}>
                          <Typography gutterBottom style={{ fontSize: "12px" }}>
                            {t("COMMON001")}
                          </Typography>
                        </Box>
                        <Box flexShrink={0}>
                          <Tooltip title={props.data.doorStatus}>
                            <Typography
                              gutterBottom
                              style={{ fontSize: "12px" }}
                            >
                              :{" "}
                              <span style={{ color: "gray" }}>
                                {props.data.doorStatus == "Active"
                                  ? t("COMMON338")
                                  : t("COMMON339")}
                              </span>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </div>
                  </Card>
                )}
                {colorPicker && (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      bgcolor="background.paper"
                    >
                      <Box>
                        <CustomColorPicker
                          color={color}
                          data={props.data}
                          openAtStart={true}
                          hideTextfield={true}
                          defaultValue={props.data.doorColor}
                          value={color}
                          setColor={(color) => setColor(color)}
                          handleClose={handleClose}
                        />
                      </Box>
                      {/* <Box >
                    <Button onClick={() => handleColorChange()}>Submit</Button>
                  </Box>
                  <Box >
                    <Button
                      onClick={() => {
                        setColor(props.data.doorColor);
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                  </Box> */}
                    </Box>
                  </>
                )}
              </div>
            </ClickAwayListener>
          }
        >
          <Grid item xs={3}>
            <IconButton
              style={{ padding: "0px" }}
              onDoubleClick={() => {
                dispatch({
                  type: "SITE_DASHBOARD_DOOR",
                  payload: props.data,
                });
              }}
            >
              {props.data.doorStatus === "Active" ? (
                <DoorIcon
                  active={isActive}
                  color={color}
                  type={props.data.doorType}
                />
              ) : (
                <CancelIcon color="disabled" />
              )}
            </IconButton>
          </Grid>
        </CustomPopover>
      )}

      {isActive && (
        <LightTooltip
          open={isActive}
          arrow
          TransitionComponent={Zoom}
          title={
            <React.Fragment>
              <Card>
                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '8px' }}>
            {CommonUtil.isEmptyString(event.profilePhoto) ? <Avatar alt='' /> : <Avatar alt='' src={'data:image/jpeg;base64,' + event.profilePhoto} />}
          </div> */}

                <div>
                  <Box display="flex" justifyContent="center">
                    <Box p={1}>
                      {CommonUtil.isEmptyString(doorAlert.profilePhoto) ? (
                        <Avatar alt="" />
                      ) : (
                        <Avatar
                          alt=""
                          src={
                            "data:image/jpeg;base64," + doorAlert.profilePhoto
                          }
                        />
                      )}
                    </Box>
                  </Box>
                  {doorAlert.name && (
                    <Box display="flex" justifyContent="center">
                      <Box p={1}>
                        <h3 style={{ fontSize: "12px" }}>{doorAlert.name}</h3>
                      </Box>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="row" p={1}>
                    <Box minWidth={"40%"}>
                      <h3 style={{ fontSize: "12px" }}>{t("Door Name ")}</h3>
                    </Box>
                    <Box flexShrink={0}>
                      <Tooltip title={props.data.doorName}>
                        <h3 style={{ fontSize: "12px" }}>
                          :{" "}
                          <span style={{ color: "gray" }}>
                            {props.data.doorName}
                          </span>
                        </h3>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" p={1}>
                    <Box minWidth={"40%"}>
                      <h3 style={{ fontSize: "12px" }}>{t("Verify Mode")}</h3>
                    </Box>
                    <Box flexShrink={0}>
                      <Tooltip title={props.data.verifyModeName}>
                        <h3 style={{ fontSize: "12px" }}>
                          :{" "}
                          <span style={{ color: "gray" }}>
                            {doorAlert.verifyModeName}
                          </span>
                        </h3>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" p={1}>
                    <Box minWidth={"40%"}>
                      <h3 style={{ fontSize: "12px" }}>{t("Event Time")}</h3>
                    </Box>
                    <Box flexShrink={0}>
                      <Tooltip
                        title={
                          CommonUtil.getLocalDate1(doorAlert.eventTime).split(
                            " "
                          )[1]
                        }
                      >
                        <h3 style={{ fontSize: "12px" }}>
                          :{" "}
                          <span style={{ color: "gray" }}>
                            {
                              CommonUtil.getLocalDate1(
                                doorAlert.eventTime
                              ).split(" ")[1]
                            }
                          </span>
                        </h3>
                      </Tooltip>
                    </Box>
                  </Box>
                </div>
              </Card>
            </React.Fragment>
          }
        >
          <Grid item xs={3}>
            <IconButton
              style={{ padding: "0px" }}
              onDoubleClick={() => {
                dispatch({
                  type: "SITE_DASHBOARD_DOOR",
                  payload: props.data,
                });
              }}
            >
              {props.data.doorStatus === "Active" ? (
                <DoorIcon
                  active={isActive}
                  color={color}
                  type={props.data.doorType}
                />
              ) : (
                <CancelIcon color="disabled" />
              )}
            </IconButton>
          </Grid>
        </LightTooltip>
      )}

      {props.path && (
        <Grid
          item
          xs={9}
          style={{
            borderTop: "1px solid #D5D5D5",
            maxWidth: "55%",
            marginLeft: "5%",
          }}
        >
          {/* <Divider variant='inset' component={'icon'} /> */}
        </Grid>
      )}
    </Grid>
  );
}

const Entrance1 = () => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(true);
  return (
    <Grid>
      <HtmlTooltip
        arrow
        disableHoverListener={false}
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(true)}
        title="Entrance"
      >
        <span></span>
      </HtmlTooltip>
    </Grid>
  );
};
