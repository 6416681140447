import {
  Avatar,
  Button,
  Card,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { makeStyles } from "@material-ui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
import idLocale from "date-fns/locale/id";
import jaLocale from "date-fns/locale/ja";
import ruLocale from "date-fns/locale/ru";
import thLocale from "date-fns/locale/th";
import zhLocale from "date-fns/locale/zh-CN";
import React, { Fragment, useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser, updateUser } from "../../actions/ProfileApiCalls";
import defaultProfile from "../../assets/images/defaultProfile.png";
import MobileInput from "../../components/MobileInput";
import BrowserLanguage from "../../components/Util/BrowserLanguage";
import CommonUtil from "../../components/Util/CommonUtils";
import setJWTToken from "../../oauth-client/setJWTToken";

export default function UserProfileScreen() {
  let language = BrowserLanguage.getDefaultLanguage();
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      marginTop: "16px",
    },
  }));
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    phone: "",
    profilePhoto: "",
  });

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    nickName: false,
    dateOfBirth: false,
    gender: false,
    email: false,
    phone: false,
  });

  const [file, setFile] = useState({});
  const [profile, setProfile] = useState(defaultProfile);

  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const store = useSelector((state) => state.security);

  var today = new Date(),
    date =
      today.getFullYear() +
      CommonUtil.formatDayAndMonth(today.getMonth() + 1) +
      CommonUtil.formatDayAndMonth(today.getDate());

  const [dob, setDob] = React.useState(null);
  const [countryCode, setCountryCode] = useState(
    CommonUtil.getLocationDetails("country_calling_code")
      ? CommonUtil.getLocationDetails("country_calling_code").replace("+", "")
      : ""
  );

  useEffect(() => {
    if (store.validToken && !CommonUtil.isEmptyString(store.AccJwtToken)) {
      setJWTToken(store.AccJwtToken);
    }

    dispatch(getUser(store.user.id));
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store)) {
      setUserData(store.userData);
      setPayload({ ...payload, ...store.userData });
      if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
        setProfile("data:image/jpeg;base64," + store.userData.profilePhoto);
      }
      if (!CommonUtil.isEmptyString(store.userData.dateOfBirth)) {
        var arr = store.userData.dateOfBirth.split("-");
        if (arr.length > 0) {
          setDob(arr[1] + "-" + arr[0] + "-" + arr[2]);
        }
      } else {
        setDob(null);
      }
    }
  }, [store]);

  useEffect(() => {
    if (!CommonUtil.isEmptyString(payload.phone)) {
      if (payload.phone.includes("-")) {
        setCountryCode(payload.phone.split("-")[0]);
        setPayload({ ...payload, phone: payload.phone.split("-")[1] });
      }
    }
  }, [payload.phone]);

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "phone") {
      if (event.target.value.match(/^[0-9 ]+$/)) {
        setPayload({
          ...payload,
          [name]: event.target.value,
        });
      }
    } else {
      setPayload({
        ...payload,
        [name]: event.target.value,
      });
    }
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleProfile = (file) => {
    if (file.type === "image/png" || file.type === "image/jpeg") {
      let fileSize = file.size.split(" ")[0];
      let size = parseInt(fileSize, 10);
      if (size <= 2000) {
        setFile(file);
        setPayload({
          ...payload,
          profilePhoto: file.base64.split(",")[1],
        });
        setProfile(file.base64);
      } else {
        setFile("");
        if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
          setProfile("data:image/jpeg;base64," + store.userData.profilePhoto);
        } else {
          setProfile(defaultProfile);
        }
        toast.error(t("REGISTRATIONFORM001"));
      }
    } else {
      setFile("");
      if (!CommonUtil.isEmptyString(store.userData.profilePhoto)) {
        setProfile("data:image/jpeg;base64," + store.userData.profilePhoto);
      } else {
        setProfile(defaultProfile);
      }
      toast.error(t("REGISTRATIONFORM002"));
    }
  };

  const handleSubmit = () => {
    if (dob == "Invalid Date" || dob > new Date()) {
      setError({ ...error, dateOfBirth: true });
      toast.error(t("USERPROFSCREEN013"));
      return;
    }

    if (CommonUtil.isEmptyString(payload.email)) {
      setError({ ...error, email: true });
      toast.error(t("USERPROFSCREEN001"));
      return;
    }
    if (CommonUtil.isEmptyString(payload.firstName)) {
      setError({ ...error, firstName: true });
      toast.error(t("USERPROFSCREEN002"));
      return;
    }

    if (!payload.firstName.match(/^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/)) {
      setError({ ...error, firstName: true });
      toast.error(t("USERPROFSCREEN003"));
      return;
    }

    if (
      !CommonUtil.isEmptyString(payload.lastName) &&
      !payload.lastName.match(/^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/)
    ) {
      setError({ ...error, lastName: true });
      toast.error(t("USERPROFSCREEN004"));
      return;
    }

    if (
      !CommonUtil.isEmptyString(payload.nickName) &&
      !payload.nickName.match(/^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/)
    ) {
      setError({ ...error, nickName: true });
      toast.error(t("USERPROFSCREEN005"));
      return;
    }

    if (
      !CommonUtil.isEmptyString(payload.phone) &&
      CommonUtil.isEmptyString(countryCode)
    ) {
      toast.error(t("COMMON193"));
      return;
    }

    var data = {};
    if (!CommonUtil.isEmptyString(payload.phone)) {
      var phone = payload.phone + countryCode.replace("+", "");
      if (phone.length < 8 || phone.length > 23) {
        setError({ ...error, phone: true });
        toast.error(t("COMPPROFSCREEN003"));
        return;
      } else {
        data = {
          ...payload,
          phone: "+" + countryCode.replace("+", "") + "-" + payload.phone,
        };
      }
    } else {
      data = { ...payload };
      setCountryCode(
        CommonUtil.getLocationDetails("country_calling_code")
          ? CommonUtil.getLocationDetails("country_calling_code").replace(
              "+",
              ""
            )
          : ""
      );
    }

    if (!CommonUtil.isEmptyString(dob)) {
      var today = new Date(dob);
      var date =
        (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
        "-" +
        (today.getMonth() + 1 < 10
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      data.dateOfBirth = date;
    } else {
      data.dateOfBirth = "";
    }
    dispatch(
      updateUser(
        data,
        (successData) => {
          setError({
            firstName: false,
            lastName: false,
            nickName: false,
            dateOfBirth: false,
            gender: false,
            email: false,
            phone: false,
          });
          setEditMode(!editMode);
        },
        (error) => {}
      )
    );
  };
  const localeMap = {
    en: enLocale,
    es: esLocale,
    zh: zhLocale,
    ja: jaLocale,
    ru: ruLocale,
    th: thLocale,
    id: idLocale,
  };
  const maskMap = {
    en: "__/__/____",
    es: "__/__/____",
    zh: "__/__/____",
    ja: "__/__/____",
    ru: "__/__/____",
    th: "__/__/____",
    id: "__/__/____",
  };

  useEffect(() => {
    setPayload({ ...payload, ...userData });
    if (!CommonUtil.isEmptyString(userData.profilePhoto)) {
      setProfile("data:image/jpeg;base64," + userData.profilePhoto);
    }
    if (!CommonUtil.isEmptyString(store.userData.dateOfBirth)) {
      var arr = store.userData.dateOfBirth.split("-");
      if (arr.length > 0) {
        setDob(arr[1] + "-" + arr[0] + "-" + arr[2]);
      }
    } else {
      setDob(null);
    }
    if (!CommonUtil.isEmptyString(userData.phone)) {
      if (userData.phone.includes("-")) {
        setCountryCode(userData.phone.split("-")[0]);
        setPayload({ ...userData, phone: userData.phone.split("-")[1] });
      }
    } else {
      setCountryCode(
        CommonUtil.getLocationDetails("country_calling_code")
          ? CommonUtil.getLocationDetails("country_calling_code").replace(
              "+",
              ""
            )
          : ""
      );
    }
    setError({
      firstName: false,
      lastName: false,
      nickName: false,
      dateOfBirth: false,
      gender: false,
      email: false,
      phone: false,
    });
  }, [editMode, userData]);

  const handleCancel = () => {
    setEditMode(!editMode);
    setError({
      firstName: false,
      lastName: false,
      nickName: false,
      dateOfBirth: false,
      gender: false,
      email: false,
      phone: false,
    });
  };

  if (!payload) return "No post!";

  var maxDateDob = new Date();
  maxDateDob.setDate(maxDateDob.getDate() - 1);

  return (
    <Fragment>
      <div className=" mb-4 text-center">
        <div className=" px-3">
          <Typography style={{ fontSize: "30px", color: "#3d4977" }}>
            {t("USERPROFSCREEN006")}
          </Typography>
        </div>
      </div>
      <Grid
        container
        spacing={4}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} lg={9}>
          <Card className="mb-4 card-body text-center">
            <Grid item xs={12}>
              <div className="d-flex align-items-center justify-content-center">
                <Avatar alt="" src={profile} className={classes.large} />
              </div>
            </Grid>
            {editMode ? (
              <Grid item xs={12}>
                <div className="d-flex align-items-center justify-content-center">
                  <IconButton
                    variant="contained"
                    component="label"
                    color="primary"
                  >
                    <PhotoCameraIcon />
                    <div style={{ display: "none" }}>
                      <FileBase64
                        multiple={false}
                        onDone={(file) => handleProfile(file)}
                      />
                    </div>
                  </IconButton>
                </div>
              </Grid>
            ) : (
              ""
            )}

            <div className="card-tr-actions">
              <Tooltip arrow title={t("COMMON129")}>
                <IconButton
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleCancel()}
                >
                  <span className="btn-wrapper--icon rounded">
                    <EditOutlinedIcon />
                  </span>
                </IconButton>
              </Tooltip>
            </div>

            <Grid container>
              <Grid item xs={12} lg={6}>
                <div className="p-3">
                  <TextField
                    className="m-0 mb-2"
                    size="small"
                    label={t("COMMON020") + "*"}
                    error={error.firstName}
                    variant="outlined"
                    placeholder={t("USERPROFSCREEN007")}
                    helperText=""
                    type="text"
                    name="firstName"
                    fullWidth
                    value={payload.firstName}
                    onChange={handleChange}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="p-3">
                  <TextField
                    className="m-0 mb-2"
                    size="small"
                    label={t("COMMON021")}
                    error={error.lastName}
                    variant="outlined"
                    placeholder={t("USERPROFSCREEN008")}
                    helperText=""
                    type="text"
                    name="lastName"
                    fullWidth
                    value={payload.lastName}
                    onChange={handleChange}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="p-3">
                  <TextField
                    className="m-0 mb-2"
                    size="small"
                    label={t("COMMON095")}
                    error={error.nickName}
                    variant="outlined"
                    placeholder={t("COMMON095")}
                    helperText=""
                    type="text"
                    name="nickName"
                    fullWidth
                    value={payload.nickName}
                    onChange={handleChange}
                    disabled={!editMode}
                    inputProps={{
                      maxlength: 50,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="p-3">
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel>{t("COMMON097")}</InputLabel>
                    <Select
                      disabled={!editMode}
                      fullWidth
                      className="m-0 mb-2 text-left"
                      helperText=""
                      label={t("COMMON097")}
                      name="gender"
                      value={payload.gender}
                      onChange={handleChange}
                      error={error.companySize}
                    >
                      <MenuItem value={"Male"}>{t("COMMON103")}</MenuItem>
                      <MenuItem value={"Female"}>{t("COMMON104")}</MenuItem>
                      <MenuItem value={"Others"}>{t("others")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="p-3">
                  <TextField
                    className="m-0 mb-2"
                    size="small"
                    label={t("COMMON042") + "*"}
                    variant="outlined"
                    error={error.email}
                    placeholder={t("COMPPROFSCREEN018")}
                    helperText=""
                    type="email"
                    name="email"
                    fullWidth
                    value={payload.email}
                    disabled
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <MobileInput
                error={error.phone}
                countryCode={countryCode}
                phone={payload.phone}
                editMode={editMode}
                onMobileNoChange={(phone) => {
                  setError({ phone: false });
                  setPayload({ ...payload, phone: phone });
                }}
                onCounryCodeChange={(code) => setCountryCode(code)}
              />
              <Grid item xs style={{ width: "100%", marginBottom: "50px" }}>
                <div className="p-3">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language == "idn" ? "id" : language]}
                  >
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        mask={maskMap[language]}
                        label={t("COMMON130")}
                        //   inputFormat='dd-MM-yyyy'
                        value={dob}
                        onChange={setDob}
                        // maxDate={maxDateDob}
                        disabled={!editMode}
                        leftArrowButtonText={t("COMMON173")}
                        rightArrowButtonText={t("COMMON174")}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            variant="outlined"
                            {...params}
                            disabled={true}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>

              {editMode ? (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <div className="p-1">
                    <Button
                      className=" m-2"
                      variant="contained"
                      size="large"
                      onClick={() => handleSubmit()}
                      style={{
                        borderRadius: "50px",
                        backgroundColor: "#3d4977",
                        color: "#fff",
                      }}
                    >
                      {t("COMMON035")}
                    </Button>
                    <Button
                      className="m-2"
                      variant="contained"
                      size="large"
                      color="default"
                      onClick={() => handleCancel()}
                      style={{ borderRadius: "50px" }}
                    >
                      {t("COMMON008")}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}
