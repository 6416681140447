import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEmployeesTrace } from "../../actions/DashBoardActions";
import in_Img from "../../assets/images/dashboard-icons/In.svg";
import out_Img from "../../assets/images/dashboard-icons/Out.svg";
import CommonUtil from "../../components/Util/CommonUtils";

export default function PersonTrace(props) {
  const { t } = useTranslation();
  const [traceEmployees, setTraceEmployees] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const dispatch = useDispatch();

  const useStyles = makeStyles({
    personCard: {
      maxHeight: 150,
      maxWidth: 230,
      background: "linear-gradient(45deg, #EFF5F3 30%, #EFF5F3 90%)",
    },

    personTraceCard: {
      maxHeight: "180px",
      maxWidth: "250px",
      border: `1px solid #B4BCC3`,
      background: "linear-gradient(45deg, #FFF 30%, #FFF 90%)",
      textalign: "left",
      color: "#464646",
      margin: "14px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    circle: {
      position: "absolute",
      top: "-9px",
      left: "-8px",
      height: "60px",
      width: "64px",

      borderRadius: "50%",
      display: "inline-block",
    },
    circleText: {
      position: "absolute",
      top: "10px",
      left: "20px",
      color: "#FFF",
    },
    circleTime: {
      position: "absolute",
      top: "30px",
      left: "20px",
      color: "#FFF",
    },
    column: {
      float: "left",
      width: "25%",
      padding: "0 10px",
    },
  });

  const dataEvents = [
    {
      reader: "Out",
      verifyMode: "Face",
      eventStatus: "warning",
      eventTime: "1",
      siteName: "1",
      eventName: "Door Inactive Time Zone Verify Open",
    },
    {
      reader: "In",
      verifyMode: "Face",
      eventStatus: "warning",
      eventTime: "2",
      siteName: "2",
      eventName: "Door Inactive Time Zone Verify Open",
    },
    {
      reader: "In",
      verifyMode: "Face",
      eventStatus: "warning",
      eventTime: "3",
      siteName: "3",
      eventName: "Door Inactive Time Zone Verify Open",
    },
    {
      reader: "In",
      verifyMode: "Face",
      eventStatus: "warning",
      eventTime: "4",
      siteName: "4",
      eventName: "Door Inactive Time Zone Verify Open",
    },
    {
      reader: "In",
      verifyMode: "Face",
      eventStatus: "warning",
      eventTime: "5",
      siteName: "5",
      eventName: "Door Inactive Time Zone Verify Open",
    },
  ];
  React.useEffect(() => {
    dispatch(
      getEmployeesTrace(props.selectedObject.pin, (data) => {
        setTraceEmployees(data.data);
      })
    );
  }, []);
  const classes = useStyles();
  const RightDivider = () => {
    return (
      <Grid
        container
        alignItems="center"
        style={{ borderRight: "1px solid #ccc" }}
        width="100%"
        minHeight="30px"
        marginRight="7%"
      ></Grid>
    );
  };

  const LeftDivider = () => {
    return (
      <Grid
        container
        item
        alignItems="center"
        style={{ borderLeft: "1px solid #ccc" }}
        width="100%"
        minHeight="30px"
        marginLeft="2%"
      ></Grid>
    );
  };
  return (
    <React.Fragment>
      <Dialog
        maxWidth="xl"
        fullWidth="xl"
        fullScreen={fullScreen}
        open={props.open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent style={{ height: "850px" }}>
          <IconButton
            className={classes.closeButton}
            onClick={() => props.setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography style={{ fontSize: "25px" }}>{t("COMMON218")}</Typography>
          <br />
          <br />
          <Card className={classes.personCard}>
            <div
              className="d-flex align-items-center py-1 "
              style={{ padding: "15px" }}
            >
              <div className="avatar-icon-wrapper rounded-circle mr-3">
                {/* <Avatar alt='...' className='img-fluid' src={props.selectedObject ? props.selectedObject.profilePhoto : ''} /> */}
                {CommonUtil.isEmptyString(props.selectedObject.profilePhoto) ? (
                  <Avatar alt="" />
                ) : (
                  <Avatar
                    alt=""
                    src={
                      "data:image/jpeg;base64," +
                      props.selectedObject.profilePhoto
                    }
                  />
                )}
              </div>
              <div className="w-150">
                <Typography
                  className="d-block font-weight-bold font-size-md"
                  variant="body"
                  color="black"
                >
                  {props.selectedObject ? props.selectedObject.name : ""}
                </Typography>
                <Typography
                  className="d-block"
                  variant="body"
                  style={{ color: "#9EA4BB" }}
                >
                  {props.selectedObject ? props.selectedObject.pin : ""}
                </Typography>
              </div>
            </div>
          </Card>
          <br />
          <br />
          <Grid
            style={{
              alignItems: "center",
              backgroundColor: "#FFFF",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              overflowX: "auto",
              overflowY: "scroll",
              height: "90vh",
              paddingBottom: "150px",
            }}
          >
            {traceEmployees.map((item, index) => {
              return (
                <Grid item lg={"3"} md={"4"} sm={"6"}>
                  <Card className={classes.personTraceCard}>
                    <CardContent>
                      <div
                        className={classes.circle}
                        style={{
                          backgroundColor:
                            item.reader == "In" ? "#36C96D" : "#FF8282",
                        }}
                      >
                        <div className={classes.circleText}>
                          {item.reader == "In"
                            ? t("COMMON284")
                            : t("COMMON285")}
                        </div>
                        <br />
                        <div className={classes.circleTime}>
                          {item.eventTime}
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img
                          alt="..."
                          className="img-fluid"
                          src={item.reader == "In" ? in_Img : out_Img}
                        />
                      </div>
                      <br />
                      {/* <Typography gutterBottom style={{ fontSize: '12px' }}>
                        Floor : <span style={{ color: 'gray' }}>2nd Floor</span>
                      </Typography> */}
                      <Typography gutterBottom style={{ fontSize: "12px" }}>
                        {t("COMMON220")} :{" "}
                        <span style={{ color: "gray" }}>{item.siteName}</span>
                      </Typography>
                      <Typography gutterBottom style={{ fontSize: "12px" }}>
                        {t("COMMON078")} :
                        <Tooltip title={item.eventName}>
                          <span style={{ color: "gray" }}>
                            {item.eventName}
                          </span>
                        </Tooltip>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
